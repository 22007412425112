<template>
    <div class="dict">
        <el-page-header
            style="margin-bottom: 1.04167vw"
            @back="goBack"
            :content="'目标列表/逾期反馈'"
        >
            <template slot="title">
                <i class="el-icon-arrow-left"></i>返回
            </template>
        </el-page-header>
        
        <el-form 
            ref="ruleForm"
            style="width: 80%"
            :model="form"
            :label-width="labelWidth"
        >
            <el-form-item label="园校：" prop="">
                <el-input type="text" disabled v-model="detail.campusName" ></el-input>
            </el-form-item>
            <el-form-item label="岗位：" prop="">
                <el-input type="text" disabled v-model="detail.jobId" ></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="">
                <el-input type="text" disabled v-model="detail.userName" ></el-input>
            </el-form-item>
            <el-form-item label="任务名称：" prop="">
                <el-input type="text" disabled v-model="detail.itemName" ></el-input>
            </el-form-item>
            <el-form-item label="截止日期：" prop="">
                <el-input type="text" disabled v-model="detail.endTime" ></el-input>
            </el-form-item>
            <el-form-item label="逾期时长：" prop="">
                <el-col :span="23">
                    <el-input type="text" disabled v-model="detail.overdue" ></el-input>
                </el-col>
                <el-col :span="1">
                    <span class="days">天</span>
                </el-col>
            </el-form-item>
            <el-form-item label="逾期原因：" prop="">
                <el-input type="textarea" v-model="form.commit" placeholder="请输入逾期原因" :maxlength="500" :rows="4"></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-button @click="goBack" class="reset">取消</el-button>
                <el-button type="primary" :loading="loading" :disabled="loading" @click="submitBtn">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loading:false,
            form:{},
            detail:{},

            labelWidth: '120px',
        }
    },
    created(){
        this.getDetail()
    },
    mounted(){
        window.addEventListener("resize",(e) => {
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
            }
            this.$forceUpdate()
        })
        if(window.innerWidth < 1300){
            this.labelWidth = '80px'
        } else {
            this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
        submitBtn(){
            this.loading = true
            this.$api.timeoutRecordSave({
               timeoutReason: this.form.commit,
               taskDetailId:this.$route.query.id
            }).then(res=>{
                this.loading = false
                if(res.data.code=='0'){
                    this.$message({
                        type: "success",
                        message: "保存成功",
                    });
                    this.goBack()
                }else if(res.data.code == 3){
                    this.goBack()
                    this.$message.error(res.data.msg)
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                this.loading = false
            })
        },
        // 获取详情
        getDetail(){
            this.$api.getTaskDetail(this.$route.query.id).then(res=>{
                if(res.data.code=='0'){
                    this.detail = res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            })

        }
    },
    beforeDestroy(){
        window.removeEventListener("resize",(e) => {
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
            }
            this.$forceUpdate()
        })
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
    background-color: #ffffff;
    height: calc(100% - 64px);
    padding: 34px 30px 30px 30px;
    .tableList {
        height: calc(100% - 100px);
    }
    .days{
        width: 100%;
        text-align: center;
        display: inline-block;
    }
}
textarea{
    font-family: inherit;
}
</style>